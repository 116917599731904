var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"sm":"12","cols":"12"}},[_c('div',{staticStyle:{"background-color":"#fff","padding":"20px 20px","border-radius":"10px"}},[_c('v-col',{attrs:{"sm":"12","cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"sm":"12","cols":"12"}},[_c('h2',{staticClass:"mb-0",staticStyle:{"font-size":"25px","font-weight":"550"}},[_vm._v(" "+_vm._s(this.$route.params.name_en)+" ")]),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color,"right":""},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")]),_c('v-btn',{staticClass:"float-right",staticStyle:{"background-color":"#00bcd4"},attrs:{"disabled":_vm.someIsNotZero == true || _vm.checked == false},on:{"click":function($event){return _vm.$router.push({
                      name: 'SessionScoreStudent',
                      params: { sess_id: _vm.sess_id, student_id: _vm.stu_id },
                    })}}},[_c('span',{staticStyle:{"font-size":"1em","color":"#000000"}},[_c('v-icon',[_vm._v(" mdi-counter ")]),_c('label',{staticClass:"ml-2 btn_create_new"},[_vm._v(_vm._s(_vm.$t("view_session_score")))])],1)])],1)],1),_c('div',{staticStyle:{"margin-top":"20px"}}),_c('v-breadcrumbs',{attrs:{"items":_vm.items,"href":_vm.items.href},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])}),(_vm.listRowCount > 0)?_c('v-row',_vm._l((_vm.listSubject),function(sub,i){return _c('v-col',{key:i,attrs:{"sm":"4","cols":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"color":"#afcdfb","dark":"","max-width":"550"},on:{"click":function($event){return _vm.$router.push({
                      name: 'ScoreStudent',
                      params: {
                        id: sub.id,
                        name: sub.name,
                        student_id: _vm.stu_id,
                      },
                    })}}},[_c('v-card-title',{staticClass:"text-h5",staticStyle:{"color":"#000000"}},[_vm._v(" "+_vm._s(sub.name)+" ")]),_c('v-card-subtitle',{staticStyle:{"font-weight":"400","color":"#000000"}},_vm._l((sub.lecturer),function(lect,index){return _c('v-chip',{key:index,staticClass:"ma-1",attrs:{"color":"indigo","label":"","text-color":"black","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-circle-outline ")]),_vm._v(" "+_vm._s(lect)+" ")],1)}),1),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":'studentt/session/subject/score/' +
                      sub.id +
                      '&' +
                      sub.name +
                      '&' +
                      _vm.stu_id,"tag":"button"}}),_c('v-card-actions',{staticStyle:{"background-color":"#a0a3fb"}})],1)],1)}),1):_c('v-row',{staticClass:"mb-5",attrs:{"align":"center","justify":"center"}},[_c('div',[_c('v-img',{staticClass:"center",attrs:{"src":"https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Fempty.png?alt=media&token=15a9dbf8-dca3-4df9-981d-f621d354e4ae"}}),_c('div',{staticClass:"text-h6 text-center"},[_vm._v(" There is no data for you to see yet ")])],1)])],1)],1)])],1)],1),_c('div',[_c('div',{staticClass:"loading"},[_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":_vm.fullPage,"opacity":0.9,"background-color":"#dedede","width":40,"height":40},on:{"update:active":function($event){_vm.isLoading=$event}}},[(_vm.myLoading)?_c('div',[_c('img',{attrs:{"width":"100%","src":"https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"}})]):_vm._e()])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }