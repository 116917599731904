<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-col sm="12" cols="12">
          <div
            style="
              background-color: #fff;
              padding: 20px 20px;
              border-radius: 10px;
            "
          >
            <v-col sm="12" cols="12">
              <v-row>
                <v-col sm="12" cols="12" class="py-0">
                  <h2 class="mb-0" style="font-size: 25px; font-weight: 550">
                    {{ this.$route.params.name_en }}
                  </h2>
                  <v-snackbar
                    :color="snackbar.color"
                    v-model="snackbar.show"
                    right
                  >
                    {{ snackbar.message }}
                  </v-snackbar>
                  <v-btn
                    class="float-right"
                    :disabled="someIsNotZero == true || checked == false"
                    style="background-color: #00bcd4"
                    @click="
                      $router.push({
                        name: 'SessionScoreStudent',
                        params: { sess_id: sess_id, student_id: stu_id },
                      })
                    "
                  >
                    <span style="font-size: 1em; color: #000000">
                      <v-icon> mdi-counter </v-icon>
                      <label class="ml-2 btn_create_new">{{
                        $t("view_session_score")
                      }}</label>
                    </span>
                  </v-btn>
                </v-col>
              </v-row>

              <div style="margin-top: 20px"></div>
              <v-breadcrumbs :items="items" :href="items.href">
                <template v-slot:divider>
                  <v-icon>mdi-chevron-right</v-icon>
                </template>
              </v-breadcrumbs>

              <v-row v-if="listRowCount > 0">
                <v-col
                  sm="4"
                  cols="12"
                  v-for="(sub, i) in listSubject"
                  :key="i"
                >
                  <v-card
                    color="#afcdfb"
                    dark
                    max-width="550"
                    class="mx-auto"
                    @click="
                      $router.push({
                        name: 'ScoreStudent',
                        params: {
                          id: sub.id,
                          name: sub.name,
                          student_id: stu_id,
                        },
                      })
                    "
                  >
                    <v-card-title style="color: #000000" class="text-h5">
                      {{ sub.name }}
                    </v-card-title>

                    <v-card-subtitle style="font-weight: 400; color: #000000">
                      <v-chip
                        v-for="(lect, index) in sub.lecturer"
                        class="ma-1"
                        color="indigo"
                        label
                        text-color="black"
                        outlined
                        :key="index"
                      >
                        <v-icon left> mdi-account-circle-outline </v-icon>
                        {{ lect }}
                      </v-chip>
                    </v-card-subtitle>
                    <router-link
                      style="text-decoration: none"
                      :to="
                        'studentt/session/subject/score/' +
                        sub.id +
                        '&' +
                        sub.name +
                        '&' +
                        stu_id
                      "
                      tag="button"
                    >
                    </router-link>

                    <v-card-actions style="background-color: #a0a3fb">
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-else align="center" justify="center" class="mb-5">
                <div>
                  <v-img
                    src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Fempty.png?alt=media&token=15a9dbf8-dca3-4df9-981d-f621d354e4ae"
                    class="center"
                  ></v-img>
                  <div class="text-h6 text-center">
                    There is no data for you to see yet
                  </div>
                </div>
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <div class="loading">
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
          :opacity="0.9"
          background-color="#dedede"
          :width="40"
          :height="40"
        >
          <div v-if="myLoading">
            <img
              width="100%"
              src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"
            />
          </div>
        </loading>
      </div>
    </div>
  </v-app>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import store from "@/store";

import { getSubjectById } from "@schoolbase/web-client-lib";
export default {
  props: ["fullPage", "alertMessage", "color", "alertText"],
  data() {
    return {
      listSubject: [],
      isLoading: false,
      myLoading: false,
      checked: false,
      stu_id: this.$route.params.student_id,
      sess_id: this.$route.params.id,
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      token: store.getters.getToken,

      items: [
        {
          text: "Major",
          disabled: false,
          href: "javascript:history.go(-1)",
        },
        {
          text: "Session",
          disabled: false,
          href: "javascript:history.go(-1)",
        },
        {
          text: "Subject",
          disabled: true,
        },
      ],

      dialog: false,
      someIsNotZero: "",
      listRowCount: [],
    };
  },
  components: {
    Loading,
  },

  methods: {
    async loadSubject() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        const APIResponse = await getSubjectById(
          this.$route.params.id,
          this.token
        );
        this.listSubject = APIResponse.payload.rows.sort(function (a, b) {
          var nameA = a.name;
          var nameB = b.name;
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        this.listRowCount = APIResponse.payload.rowCount;
        let generate = APIResponse.payload.rows.map(
          (item) => item.score_approval
        );
        this.someIsNotZero = generate.some((item) => item == 0);

        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }

        this.isLoading = false;
        this.myLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.myLoading = false;

        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
      if (this.listRowCount > 0) {
        return (this.checked = true);
      } else {
        return (this.checked = false);
      }
    },
  },

  async mounted() {
    this.loadSubject();
  },
};
</script>
<style scoped>
.v-data-table thead th {
  font-size: 16px !important;
  text-align: center !important;
}
.v-data-table tbody {
  text-align: center !important;
}

.btn_edit {
  background-color: #4e6470 !important;
  color: #fff !important;
  height: 35px;
}

.v-card__actions .v-btn.v-btn {
  padding: 0 16px;
}

.btn_create_new {
  color: #000000 !important;
  text-transform: capitalize !important;
  height: 40px !important;
  border: none !important;
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 14px;
}

.tab_wrapper {
  position: relative;
  display: inherit;
}

.v-tab {
  min-width: 30px;
  font-size: 15px;
  text-transform: capitalize;
}

.v-icon--left {
  margin-right: 0px;
}

.dropdown_transactions.v-btn {
  background-color: #2ca01c !important;
  height: 40px !important;
  width: auto !important;
  border-radius: 0 !important;
  padding: 0px 10px 0px 10px !important;
  color: #fff !important;
  right: 12px;
  position: absolute;
  top: 7px;
}

@media (max-width: 576px) {
}
</style>
